import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import cogoToast from "cogo-toast";
import { BACKEND_URL } from "../constants"
import Footer from "../Footer";


const AddPage = () => {
  const [title, setTitle] = useState("");
  const [loading, setLoading]= useState(false)
  const navigate = useNavigate()

  const onChange = (e) => {
    if (e.target.name === "title") {
      setTitle(e.target.value);
    } 
  };

  const addSite = async () => {
    try{
      const response = await fetch(`${BACKEND_URL}/addPage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: title
      }),
    });
    const result = await response.json();

    if (result.statusCode === 200) {
      setLoading(false)
      cogoToast.success(
        "Strona została dodana pomyślnie"
      );
      navigate("/");
    } else {
      cogoToast.error("Nieprawidłowe dane");
    } 
  }
  catch (error) {
    console.error("erorr: ", error);
  }}
  return (
    <>
      <div className="login-user-container">
        <div className="login-user-box">
          <div className="login-user-header">
            <p className="login-user-header-text">Dodaj nową stronę</p>
          </div>
          <div className="login-user-placeholder">
            <input
              style={{padding:"10px"}}
              name="title"
              type="text"
              placeholder="Dodaj tytuł strony"
              onChange={onChange}
              className="login-user-line"
            />
          </div>
          <button onClick={addSite} className="login-user-button">
            Stwórz nową stronę
          </button>
        </div>
      </div>
      <Footer/>
    </>
  );
};
export default AddPage;
