import { useState, useEffect, useRef } from "react"
import { useNavigate,useParams } from "react-router-dom"
import Files from 'react-files'
import PublitioAPI from 'publitio_js_sdk'
import cogoToast from "cogo-toast"
import { BACKEND_URL } from "../constants"
import { useForm } from "react-hook-form";
import Footer from "../Footer"

const EditPages = () => {
  const publitio = new PublitioAPI('Bd18D5TejVlG6vhm1lrG', 'ZsDXHq9Jmz9BMxW3encRZ0JD48hVPdfZ')
    const [subPage, setSubPage] = useState()
    const params = useParams()
    const [pdf,setPdf]= useState("")
    const initialized = useRef(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const {
      register,
      handleSubmit,
      getValues,
      formState: { errors },
    } = useForm({
      mode: "onBlur",
      defaultValues: {
        name:"", 
        description:"",
        link:'',
      },
    });

    const handleChange = (files) => {
      setPdf(files[0])
    }

    const handleError = (error, file) => {
      console.log('error code ' + error.code + ': ' + error.message)
    }

    useEffect(() => {
      if (!initialized.current) {
        initialized.current = true;
        setLoading(true);
        setTimeout(() => {
          fetch(`${BACKEND_URL}/subPages/${params.id}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "ngrok-skip-browser-warning": true,
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (!response.hasOwnProperty("error")) {
                setSubPage(response);
              }
            })
            .catch((error) => {
              console.error("error: ", error);
            })
            .finally(() => setLoading(false));
        }, [1000]);
      }
    }, [subPage]);

    const EditSubPages = async () => {
      let data;
      let values = getValues()
      try{
        if(pdf !== "" ){
          data = await publitio.uploadFile(pdf, 'file')
        }
        const response = await fetch(`${BACKEND_URL}/subPages`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: params.id,
          name:values.name !== "" ? values.name: subPage?.name,
          description:values.description !== "" ? values.description : subPage?.description,
          link:values.link !== "" ? values.link : subPage?.link,
          pdf: pdf !== "" ? data.url_preview: subPage?.pdf,
          page_id: params.page_id
        }),
      });
      const result = await response.json();

      if (result.statusCode === 200) {
        setLoading(false)
        cogoToast.success(
          "Dane podstrony zaaktualizowane"
        );
        window.location.reload()
      } else {
        cogoToast.error("Nieprawidłowe dane");
      } 
    }
    catch (error) {
      console.error("error: ", error);
    }}

    return(
        <>
      <div className="login-user-container">
        <div className="login-user-box">
          <div className="login-user-header">
            <p className="login-user-header-text">Edycja Podstrony</p>
          </div>
              <div className="login-user-placeholder">
                <div style={{display:"flex", flexFlow:'column', alignItems:'center'}}>
            <p style={{color:"black"}}> Tytuł podstrony: {subPage?.name}</p>
            <input 
              {...register("name", {
                maxLength: 300,
              })}
              name="name"
              className="login-user-line"
            />
            <div style={{display:'flex', gap:"10px", marginTop:"10px"}}>
             </div>
             <br></br>
             <p style={{color:"black"}}>Opis: {subPage?.description}</p>
            <textarea   {...register("description", {
              maxLength: 1000,
            })} name="description"
              className="login-user-line"/>
                <div style={{display:'flex', gap:"10px", marginTop:"10px"}}>
             </div>
               <br></br>
            <p style={{color:"black"}}> Link do yt: {subPage?.link}</p>
            <input {...register("link", {
              maxLength: 300,
            })} type="text" name="link" 
              className="login-user-line"/>
                <div style={{display:'flex', gap:"10px", marginTop:"10px"}}>
             </div>
               <br></br>
               <p style={{color:"#51abe9"}}>{subPage?.pdf}</p>
               <Files
      style={{cursor:"pointer", backgroundColor:"#51abe9", padding:"20px", width:"77%"}}
        className='files-dropzone'
        onChange={handleChange}
        onError={handleError}
        accepts={['image/png', '.pdf', 'audio/*']}
        multiple
        maxFileSize={10000000}
        minFileSize={0}
        clickable>
          Plik w pdfie
      </Files>
            </div>
          </div>
         <button style={{marginTop:"20px"}} className="btn btn-primary" onClick={EditSubPages}>Zauktualizuj zmiany</button>
          {/* <select {...register("page_id", {
              maxLength: 30,
            })} name="page">
            <option value=""></option>
              {subPages !== undefined  && pages.map((option) => {
                return <option key={option.id} id={option.id} value={option.page_id}>{option.title}</option>
                 })}
            </select> */}
        </div>
        </div>
        <Footer/>
    </>
    )
}
export default EditPages