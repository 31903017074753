import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import Files from 'react-files'
import PublitioAPI from 'publitio_js_sdk'
import axios from "axios";
import cogoToast from "cogo-toast"
import { BACKEND_URL } from "../constants"
import { useForm } from "react-hook-form";
import Footer from "../Footer"
import { toBeEmptyDOMElement } from "@testing-library/jest-dom/matchers"

const ChoosePage = () => {
  // const [name,setName] = useState("")
  const publitio = new PublitioAPI('Bd18D5TejVlG6vhm1lrG', 'ZsDXHq9Jmz9BMxW3encRZ0JD48hVPdfZ')
    const [subPages, setSubPages] = useState([]);
    const [pages, setPages] = useState([])
    const [id, setId] = useState(0)
    const [pageId,setPageId]= useState(0)
    const initialized = useRef(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const changeId = (e) => {
      setId(e.target.value)
    }

    const changePageId = (e) => {
      setPageId(e.target.value)
    }

    const toEdit = () => {
      navigate(`/editSubPages/${id}/${pageId}`)
    }

    useEffect(() => {
      if (!initialized.current) {
        initialized.current = true;
        setLoading(true);
        setTimeout(() => {
          fetch(`${BACKEND_URL}/AllSubPages`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "ngrok-skip-browser-warning": true,
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (!response.hasOwnProperty("error")) {
                setSubPages(response);
              }
            })
            .catch((error) => {
              console.error("error: ", error);
            })
            .finally(() => setLoading(false));
        }, [1000]);
      }
    }, [subPages]);


    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
          fetch(`${BACKEND_URL}/pages`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "ngrok-skip-browser-warning": true,
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (!response.hasOwnProperty("error")) {
                setPages(response);
              }
            })
            .catch((error) => {
              console.error("error: ", error);
            })
            .finally(() => setLoading(false));
        }, [1000]);
    }, []);

    return(
        <>
      <div className="login-user-container">
        <div className="login-user-box">
          <div className="login-user-header">
            <p className="login-user-header-text">Wybierz strone i podstrone</p>
          </div>
          <div style={{flexFlow:"column", gap:"20px"}} className="login-user-placeholder">
          <select onChange={changePageId} name="page_id">
            <option value=""></option>
              {pages.map((option) => {
                return <option key={option.id} id={option.id} value={option.id}>{option.title}</option>
                 })}
            </select>
           <select onChange={changeId} name="id">
            <option value=""></option>
              {subPages !== undefined  && subPages.filter((option) => option.page_id == pageId )
              .map((option) => {
                return <option key={option.id} id={option.id} value={option.id}>{option.name}</option>
                 })}
            </select>
          </div>
         <button style={{marginTop:"20px"}} className="btn btn-primary" onClick={toEdit}>Zacznij edycje</button>
        </div>
        </div>
        <Footer/>
    </>
    )
}
export default ChoosePage