import { useEffect, useRef, useState } from "react"
import { useParams,useSearchParams } from "react-router-dom"
import { BACKEND_URL } from "../constants"
import { pdfjs } from 'react-pdf';
import ClipLoader from "react-spinners/ClipLoader";
import ReactPlayer from 'react-player'
import cogoToast from "cogo-toast"
import { Document, Page } from 'react-pdf';
import PagingControl from "./PaggingControl"

const Template = ({ page }) => {
    const params = useParams()
    const initialized = useRef(false);
    const [numPages, setNumPages] = useState(0);
    const [pageNum, setPageNum] = useState(1);
    const pageDetailsRef = useRef(null);
    // const [page, setpage] = useState(null);
    const [loading,setLoading] = useState(false)
    const [searchParams] = useSearchParams()

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

    const onDocumentLoadSuccess = ({ numPages }) => {
        setTimeout(() => {
          setNumPages(numPages);
        },[1000])
      }

      const handlePageNum = (pageNum) => {
        setPageNum(pageNum);
      }

    // useEffect(() => {
    //     if (!initialized.current) {
    //       initialized.current = true;
    //       setLoading(true)
    //       setTimeout(() => {
    //         fetch(`${BACKEND_URL}/pages/${params.id}`, {
    //           method: "GET",
    //           headers: {
    //             "Content-Type": "application/json",
    //             "ngrok-skip-browser-warning": true,
    //           },
    //         })
    //           .then((response) => response.json())
    //           .then((response) => {
    //             if (!response.hasOwnProperty("error")) {
    //               setpage(response[0]);
    //               setLoading(false)
    //             }
    //           })
    //           .catch((error) => {
    //             console.error("error: ", error);
    //           })
    //           .finally(() => setLoading(false));
    //       }, [1000]);
    //     }
    //   }, [page]);

    //   if (!page) {
    //     return null;
    //   }

    return (
        <div className="template">
            {loading === true ? 
            <div style={{marginTop:'40px'}}><ClipLoader color="black" size={50} aria-label="Loading Spinner" data-testid="loader"/></div>: 
            <div key={page.id} style={{paddingTop:"40px", display:"flex", gap:"5px", alignItems:"center", flexFlow:'column'}}>
                <h2 style={{color:"white", fontFamily:"poppins", fontSize:"40px"}}>{page?.name}</h2>
                <p style={{maxWidth:'400px', color:"white", fontFamily:"poppins"}}>{page?.description}</p>
                <ReactPlayer url={page?.link} />
                <div style={{paddingBottom:"20px"}}></div>
                <Document file={searchParams.get('link')} onLoadSuccess={onDocumentLoadSuccess}>
                <Page
                pageNumber={pageNum}
                width={644}
                height={450} 
                onLoadSuccess={(data) => {
                  pageDetailsRef.current = data;
                }}
              />
        </Document>
        <PagingControl
            pageNum={pageNum}
            onPageChange={handlePageNum}
            totalPages={numPages}
          />
                <a style={{color:"orange", fontSize:"27px", marginBottom:"30px",cursor:"pointer"}} href={page?.pdf}>Pobierz pdf</a>
            </div>}
    </div>
    )
}
export default Template;